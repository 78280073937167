import {hookToAttributeSelector, MembersModal} from '@wix/wix-events-commons-statics'
import {VisitorType} from '@wix/events-types'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {getLanguage} from '../../../../commons/selectors/environment'
import {openModal} from '../../../../commons/services/modal'
import {isMember} from '../selectors/current-member-details'
import {isRestrictedTo} from '../selectors/event'
import {inDetails, inPickTickets} from '../selectors/navigation'
import {isPaidPlansEnabled} from '../selectors/paid-plans'
import {GetState, StoreExtraArgs} from '../types'
import {setActiveElement} from '../../../../commons/actions/focus-handler'
import {getMembers} from './event'
import {getPlanList} from './paid-plans'
import {getMemberRsvp} from './rsvp'

export const PROMPT_LOGIN = createActions('PROMPT_LOGIN')
export const FETCH_CURRENT_MEMBER = createActions('FETCH_CURRENT_MEMBER')

export const ensureLoggedIn = () => (dispatch: Function, getState: GetState) => {
  if (isMember(getState())) {
    return true
  }

  return dispatch(promptLogin())
}

export const promptLogin =
  () =>
  async (dispatch: any, getState: GetState): Promise<boolean> => {
    const loggedIn = await dispatch(callAPI(PROMPT_LOGIN, getLanguage(getState())))
    return loggedIn
  }

export const ensureLoginForMembersOnly = () => async (dispatch: Function, getState: GetState) => {
  const state = getState()

  if (isRestrictedTo(state, VisitorType.MEMBER)) {
    return dispatch(ensureLoggedIn())
  } else {
    return true
  }
}

export const openMembersModal = (eventId: string) => async (dispatch: Function) => {
  const loggedIn = await dispatch(ensureLoggedIn())
  if (loggedIn) {
    await dispatch(openModal({type: MembersModal.LIST, params: {eventId}}))
    dispatch(setActiveElement(hookToAttributeSelector(DH.EVENT_DETAILS_MEMBERS)))
  }
}

export const fetchCurrentMember = () => (dispatch: Function, getState: GetState) => {
  if (getState().membersAreaEnabled) {
    return dispatch(callAPI(FETCH_CURRENT_MEMBER))
  }
}

export const addLoginListener =
  () =>
  async (dispatch: Function, getState: GetState, {wixCodeApi}: StoreExtraArgs) => {
    wixCodeApi.user.onLogin(async (user: any) => {
      if (user.loggedIn) {
        await dispatch(fetchCurrentMember())
        await dispatch(getMemberRsvp())
        dispatch(getMembers())
        const state = getState()
        if ((inDetails(state) || inPickTickets(state)) && isPaidPlansEnabled(state)) {
          dispatch(getPlanList())
        }
      }
    })
  }
