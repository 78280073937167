import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Divider} from 'wix-ui-tpa/Divider'
import {Text} from 'wix-ui-tpa/Text'
import {getTicketPriceText} from '../../../../../selectors/order-success'
import {classes} from './whole-element-reservation.st.css'
import {WholeElementReservationProps} from '.'

export const WholeElementReservation = ({
  place: {places, reservationOptions, ticket},
  basketView,
  inDescription,
  showAccessibilityMode,
}: WholeElementReservationProps) => {
  const {t} = useTranslation()
  const wholeElementReservation = Boolean(reservationOptions?.reserveWholeElement)

  return wholeElementReservation ? (
    <div className={basketView || inDescription || showAccessibilityMode ? classes.container : ''}>
      <Divider className={classes.divider} />
      <Text className={classes.tableReservation}>
        {t('seating_tableReservation', {
          price: getTicketPriceText(
            {
              amount: String(Number(ticket.price.amount) * places.length),
              currency: ticket.price.currency,
            },
            t,
          ),
        })}
      </Text>
    </div>
  ) : null
}
