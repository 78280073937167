import {isDraft} from '@wix/wix-events-commons-statics'
import {createAction} from '@reduxjs/toolkit'
import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {isTemplate} from '../../../../commons/selectors/instance'
import {isRequestPending} from '../selectors/pending-requests'
import {GetState, SelectedTickets, StoreExtraArgs, TicketsToPlaces} from '../types'
import {isUpgradeNeededForCheckout} from '../selectors/checkout-options'
import {isMobile} from '../../../../commons/selectors/environment'
import {UserRole} from '../constants/constants'
import {Interaction} from '../utils/interactions'
import {getTicketsToPlaces} from '../selectors/seating'
import {openCheckoutUnavailable, openUpgradeToPremium} from './modals'
import {navigateToMobileCheckout, navigateToTicketForm} from './navigation'

export const RESERVE_TICKETS = createActions('RESERVE_TICKETS')
export const CANCEL_RESERVATION = createActions('CANCEL_RESERVATION')

export const reserveTickets =
  (eventId: string, selectedTickets: SelectedTickets) =>
  (dispatch: Function, getState: GetState, {wixCodeApi}: StoreExtraArgs) => {
    const state = getState()
    const upgradeNeeded = isUpgradeNeededForCheckout(state)
    const owner = wixCodeApi.user.currentUser.role === UserRole.ADMIN
    const ticketsToPlaces = getTicketsToPlaces(state)

    if (isTemplate(state) || isDraft(state.event)) {
      return isMobile(state) ? dispatch(navigateToMobileCheckout()) : dispatch(navigateToTicketForm())
    }

    if (upgradeNeeded && (isMobile(state) || !owner)) {
      return dispatch(openCheckoutUnavailable())
    }

    if (upgradeNeeded && owner) {
      return dispatch(openUpgradeToPremium({eventId, selectedTickets, ticketsToPlaces}))
    }

    return dispatch(makeReservation(eventId, selectedTickets, ticketsToPlaces))
  }

export const cancelReservation = (eventId: string, reservationId: string) => (dispatch: Function) => {
  if (reservationId) {
    dispatch(callAPI(CANCEL_RESERVATION, eventId, reservationId))
  }
}

export const makeReservation =
  (eventId: string, tickets: SelectedTickets, ticketsToPlaces: TicketsToPlaces) =>
  async (dispatch: Function, getState: GetState, {flowAPI}: StoreExtraArgs) => {
    const state = getState()

    if (!isRequestPending(state, RESERVE_TICKETS.REQUEST)) {
      flowAPI.fedops.interactionStarted(Interaction.CreateReservation)
      await dispatch(callAPI(RESERVE_TICKETS, eventId, tickets, ticketsToPlaces))
      isMobile(state) ? dispatch(navigateToMobileCheckout()) : dispatch(navigateToTicketForm())
    }
  }

export const clearReservationError = createAction<boolean>('CLEAR_RESERVATION_ERROR')
